/**
 * @param {number} length
 * @returns {string}
 */
function makeid(length) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; ++i) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

/**
 * @param {string} url
 * @returns {string}
 *
 * console.log(cleanURL('http://www.google.fr//test/')); // http://www.google.fr/test
 * console.log(cleanURL('http://www.google.fr//test'));  // http://www.google.fr/test
 * console.log(cleanURL('http://www.google.fr/test/'));  // http://www.google.fr/test
 * console.log(cleanURL('http://www.google.fr/test'));   // http://www.google.fr/test
 *
 * Prend en charge le protocole (HTTP / HTTPS)
 */
function cleanURL(url) {
    var url = url.replace(/http(s)?:\/\//, 'http$1:@').replace(/\/\/+/g, '/').replace(/@/g, '//');
    if (url[url.length - 1] === '/') {
        url = url.slice(0, -1);
    }
    return url;
}

/**
 * @param {object} object
 * @param {function} valueCallback
 * @returns {object}
 */
function sortValueInt(object, valueCallback) {
    if (typeof valueCallback !== 'function') {
        valueCallback = function (value) {
            return value;
        };
    }

    // Création d'objet temporaire qui contient les positions
    // et les valeurs en minuscules.
    var mapped = object.map(function (e, i) {
        return {_idxkey_: i, value: parseInt(valueCallback(e))};
    });

    // On trie l'objet temporaire avec les valeurs réduites.
    mapped.sort(function (a, b) {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        return 0;
    });

    // On utilise un objet final pour les résultats.
    return mapped.map(function (e) {
        return object[e._idxkey_];
    });
}

export var globalListener = (function () {
    /**
     * @type {Array}
     */
    var listeners = [];

    /**
     * @returns {object}
     */
    var createInstance = function () {
        return {
            /**
             * @param {function} callback
             */
            add: function (callback) {
                if (callback !== undefined && typeof callback === 'function') {
                    listeners.push(callback);
                }
            },

            /**
             * @param {string} key
             * @param {mixed} key
             */
            trigger: function (key, value) {
                if (typeof key === 'string') {
                    for (var k in listeners) {
                        listeners[k](key, value);
                    }
                }
            }
        };
    };

    var instance;

    return {
        /**
         * @returns {object}
         */
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

var winClick = (function () {
    var registerList = [];

    $(window).on('click', function (e) {
        $(registerList).each(function () {
            this(e);
        });
    });

    return {
        /**
         * @param {function} callback
         */
        register: function (callback) {
            if (typeof callback === 'function') {
                registerList.push(callback);
            }
        }, // register ();

        /**
         * @param {function} callback
         */
        unregister: function (callback) {
            var index = registerList.indexOf(callback);
            if (index > -1) {
                registerList.splice(index, 1);
            }
        }
    };
})();

(function ($) {
    /**
     * @param {jQuery} el
     */
    var applyDisabled = function (el) {
        if (el.next('input.hiddenvalue').length === 0) {
            var attr = {type: 'hidden', name: el.attr('name'), value: el.val()};
            el.before($('<input>').attr(attr).addClass('hiddenvalue'));
        }
    };

    /**
     * @returns {jQuery}
     */
    $.fn.disabledSelect = function () {
        return this.find('select[disabled]').each(function () {
            applyDisabled($(this));
        });
    };

    /**
     * @returns {jQuery}
     */
    $.fn.disabledCheckbox = function () {
        return this.find('input[type="checkbox"][disabled]').each(function () {
            applyDisabled($(this));
        });
    };
}(jQuery)); // disabledSelect / disableRadio

(function ($) {
    /**
     * @returns {jQuery}
     */
    $.fn.chooseDefault = function () {
        return this.find('[data-radio-default]').each(function () {
            if ($(this).find('input[type="radio"]:checked').length === 0) {
                var target = $(this).find('input[type="radio"][value="' + $(this).data('radio-default') + '"]');
                if (target.length === 1) {
                    target.prop('checked', 1);
                }
            }
        });
    };
}(jQuery)); // chooseDefault

(function ($) {
    /**
     * @param {object} params
     * @returns {jQuery}
     */
    $.fn.multipleField = function (params) {
        /**
         * @param {jQuery} collectionHolder
         */
        var instanciateFields = function (collectionHolder) {
            var options = $.extend(true, {
                cssClass: {
                    field: 'field',
                    add: 'multifield-add',
                    add_ctnr: 'multifield-add-ctnr',
                    remove: 'multifield-remove',
                    container: 'multiplefield-form',
                    sortableHandle: 'multiplefield-sorthandle'
                },
                data: {
                    addLabel: 'multifield-addlabel',
                    removeLabel: 'multifield-removelabel',
                    sortableLabel: 'multifield-sortablelabel',
                    ifempty: 'multifield-ifempty',
                    randomvalue: 'multifield-randomvalue',
                    sortable: 'multifield-sortable',
                    limit: 'multifield-limit'
                }
            }, params);

            var addButton = $('<a href="#">' + collectionHolder.data(options.data.addLabel) + '</a>')
                .addClass(options.cssClass.add);

            var addContainer = $('<p>').append(addButton).addClass(options.cssClass.add_ctnr);

            var sortableInstance = null;
            if (collectionHolder.data(options.data.sortable) !== undefined) {
                sortableInstance = new Sortable(collectionHolder.get(0), {
                    animation: 200,
                    handle: '.' + options.cssClass.sortableHandle,
                    preventOnFilter: true,
                    draggable: '.' + options.cssClass.container,
                    scroll: true,
                    scrollSensitivity: 30,
                    scrollSpeed: 10,
                    onEnd: function (event) {
                        applySortableValues();
                    }
                });
            }

            collectionHolder.append(addContainer);
            collectionHolder.data('index', collectionHolder.find(':input').length);

            addButton.on('click', function (e) {
                e.preventDefault();
                globalListener.getInstance().trigger('initform_before', null);
                var form = addEmbedForm();
                if (form !== null) {
                    // Peut valoir null dans le cadre d'une limitation.
                    globalListener.getInstance().trigger('initform_after', form);
                }
            });

            function applyLimitation() {
                var limit = collectionHolder.data(options.data.limit);
                if ($.isNumeric(limit)) {
                    if ((limit = parseInt(limit)) > 0) {
                        if (collectionHolder.find('.' + options.cssClass.container).length >= limit) {
                            addContainer.hide();
                        } else {
                            addContainer.show();
                        }
                    }
                }
            }

            function applySortableValues() {
                // On recalcule la position des éléments entre eux.
                var sortableClass = collectionHolder.data(options.data.sortable);
                collectionHolder.find('.' + options.cssClass.container).each(function (i) {
                    var index = collectionHolder.find('.' + options.cssClass.container).index($(this));
                    $(this).find('input.' + sortableClass).val(index);
                });
            }

            /**
             * @returns {object}
             */
            function addEmbedForm() {
                var prototype = collectionHolder.data('prototype');
                var index = collectionHolder.data('index');

                var newForm = prototype;
                newForm = newForm.replace(/__name__label__/g, index);
                newForm = newForm.replace(/__name__/g, index);
                newForm = $(newForm).addClass(options.cssClass.container);

                var emptyDataValue = newForm.find('[data-' + options.data.ifempty + ']');
                if (emptyDataValue.length) {
                    emptyDataValue.val(emptyDataValue.data(options.data.ifempty));
                }

                var randomValue = newForm.find('[data-' + options.data.randomvalue + ']');
                if (randomValue.data(options.data.randomvalue) === 1) {
                    randomValue.val(makeid(6));
                }

                collectionHolder.data('index', index + 1);
                addContainer.before(newForm);

                applyLimitation();

                addRemoveButton(newForm);

                if (sortableInstance !== null) {
                    appendSortableHandle(newForm);
                    applySortableValues();
                }

                return newForm;
            }

            /**
             * @param {jQuery} entry
             */
            function addRemoveButton(entry) {
                var removeButton = $('<a href="#">' + collectionHolder.data(options.data.removeLabel) + '</a>')
                    .addClass(options.cssClass.remove);

                entry.append(removeButton);

                removeButton.on('click', function (e) {
                    e.preventDefault();
                    globalListener.getInstance().trigger('removeform_before', entry);
                    entry.remove();
                    applyLimitation();
                    globalListener.getInstance().trigger('removeform_after', null);
                });
            }

            /**
             * @param {jQuery} entry
             */
            function appendSortableHandle(entry) {
                var sortableHandle = $('<span>' + collectionHolder.data(options.data.sortableLabel) + '</span>')
                    .addClass(options.cssClass.sortableHandle);

                entry.prepend(sortableHandle);

                // On masque l'entrée qui ne doit plus être utilisée.
                entry.find('.' + collectionHolder.data(options.data.sortable))
                    .closest('.' + options.cssClass.field).hide();
            }

            collectionHolder.find('>div').each(function () {
                var current = $(this);

                current.addClass(options.cssClass.container);

                addRemoveButton(current);

                if (sortableInstance !== null) {
                    appendSortableHandle(current);
                    applySortableValues();
                }
            });

            applyLimitation();
        };

        return this.each(function () {
            instanciateFields($(this));
        });
    };
}(jQuery)); // multipleField

(function ($) {
    /**
     * Ce plugin travaille sur tous les champs d'un formulaire au moment du submit.
     * En ce sens, les éventuels champs ajoutés durant l'utilisation dudit formulaire (ex avec $.multipleField)
     * sont aussi pris en comptes, inutile de réaliser de nouvelles instanciations !
     *
     * @param {object} params
     * @returns {jQuery}
     */
    $.fn.constraintValidation = function (params) {
        /**
         * @param {object} form
         */
        var instanciateValidationForm = function (form) {
            var options = $.extend(true, {
                cssClass: {
                    container: 'field',
                    containerError: 'field-error',
                    errorMessage: 'field-error-message'
                },
                initialized: function (form) {
                },
                beforeSubmit: function (form) {
                },
                error: function (form) {
                },
                success: function (form) {
                }
            }, params);

            var submit = $(form).find('[type="submit"]');

            /**
             * @param {boolean} checkValidity
             * @returns {{is_valid: boolean, count_error: number}}
             */
            function checkForm(checkValidity) {
                options.beforeSubmit(form);

                var countError = 0;

                $(form.elements).each(function () {
                    var parent = $(this).closest('.' + options.cssClass.container);

                    if (checkValidity) {
                        parent.find('.' + options.cssClass.errorMessage).remove();
                    }

                    if (parent.length === 1) {
                        if (checkValidity) {
                            parent.removeClass(options.cssClass.containerError);
                            if (!this.validity.valid) {
                                parent.append(
                                    $('<p>')
                                        .addClass(options.cssClass.errorMessage)
                                        .html(this.validationMessage)
                                );
                            }
                        }

                        if (parent.find('.' + options.cssClass.errorMessage).length > 0) {
                            // Message d'erreur présent.
                            parent.addClass(options.cssClass.containerError);
                            ++countError;
                        }
                    }
                });

                if (countError > 0) {
                    if (typeof options.error === 'function') {
                        options.error(form);
                    }
                } else {
                    if (typeof options.success === 'function') {
                        options.success(form);
                    }
                }

                return {
                    is_valid: countError === 0,
                    count_error: countError
                };
            }

            submit.on('click', function (e) {
                if (!checkForm(true).is_valid) {
                    // A la moindre erreur, le formulaire n'est pas envoyé.
                    e.preventDefault();
                }
                globalListener.getInstance().trigger('on_constraint_submit', $(form));
            });

            if (typeof options.initialized === 'function') {
                options.initialized(form);
                checkForm(false);
            }
        };

        return this.each(function () {
            instanciateValidationForm(this);
        });
    };
}(jQuery)); // constraintValidation

(function ($) {
    /** @type {Array} */
    var visibilityCalls = [];

    /**
     * @param {jQuery} target
     */
    var enableForm = function (target) {
        // Volontairement ciblé sur tous les noeuds enfants qui possèdent cet attribut.

        target.attr('data-skip-dynamic', 0);

        target.show().find('[data-required="1"]').each(function () {
            $(this).attr('required', 'required').removeAttr('data-required');
        });
    };

    /**
     * @param {jQuery} target
     */
    var disableForm = function (target) {
        // Volontairement ciblé sur tous les noeuds enfants qui possèdent cet attribut.

        target.attr('data-skip-dynamic', 1);

        target.hide().find('[required]').each(function () {
            $(this).attr('data-required', 1).removeAttr('required');
        });
    };

    var triggerVisibilityAction = function () {
        $(visibilityCalls).each(function () {
            var $$ = this;
            //setTimeout(function () {
            $$['func']($$.input, $$.container, $$.options);
            //}, 100);
        });
    }; // triggerVisibilityAction();

    /**
     * @param {jQuery} input
     * @returns {boolean}
     */
    var skipDynamic = function (input) {
        var result = false;

        var parentDynamic = input.closest('[data-skip-dynamic]');
        if (parentDynamic.length === 1) {
            //if (parseInt(parentDynamic.data('skip-dynamic')) === 1) { // NE FONCTIONNE PAS !!
            if (parseInt(parentDynamic.attr('data-skip-dynamic')) === 1) {
                result = true;
            }
        }

        return result;
    }; // skipDynamic();

    var initializeDynamicForm = function (el, input, visibilityCallback, submitCallback, params) {
        var options = $.extend(true, {
            cssClass: {
                field: 'field',
                dynYes: 'dynamic-yes',
                dynNo: 'dynamic-no'
            },
            data: {
                target: 'dynamic-targetid'
            }
        }, params);

        var conditionnalContentFields = el.closest('.' + options.cssClass.field);

        if (conditionnalContentFields.length === 1) {
            var dynamicContentFields = $('#' + el.data(options.data.target));

            if (dynamicContentFields.length === 1) {
                input.on('change', function () {
                    triggerVisibilityAction();
                });

                el.closest('form').on('submit', function (e) {
                    submitCallback(e, input, dynamicContentFields, options);
                });

                visibilityCalls.push({
                    func: visibilityCallback,
                    input: input,
                    container: dynamicContentFields,
                    deep: dynamicContentFields.parents().length,
                    options: options
                });

                // On trie a liste des callbacks en fonction de leurs profondeurs pour
                // les appeler dans le bon ordre.
                visibilityCalls = sortValueInt(visibilityCalls, function (element) {
                    return element.deep;
                });

                triggerVisibilityAction();
            }
        }
    };

    /**
     * @param {object} params
     * @returns {jQuery}
     */
    $.fn.dynamicFormRadio = function (params) {
        return this.each(function () {
            var el = $(this);
            initializeDynamicForm(el, el.find('input[type="radio"]'),
                function (input, fieldsContainer, options) { // visibilityCallback.
                    if (skipDynamic(input)) {
                        disableForm(fieldsContainer.find('.' + options.cssClass.dynNo));
                        disableForm(fieldsContainer.find('.' + options.cssClass.dynYes));
                    } else {
                        var val = parseInt(input.filter(':checked').val());
                        if (val === 1) {
                            disableForm(fieldsContainer.find('.' + options.cssClass.dynNo));
                            enableForm(fieldsContainer.find('.' + options.cssClass.dynYes));
                        } else if (val === 0) {
                            disableForm(fieldsContainer.find('.' + options.cssClass.dynYes));
                            enableForm(fieldsContainer.find('.' + options.cssClass.dynNo));
                        }
                    }
                },
                function (e, input, fieldsContainer, options) { // submitCallback.
                    var val = parseInt(input.filter(':checked').val());
                    if (val === 1) {
                        fieldsContainer.find('.dynamic-no').remove();
                    } else if (val === 0) {
                        fieldsContainer.find('.dynamic-yes').remove();
                    }
                },
                params);
        });
    };

    /**
     * @param {object} params
     * @returns {jQuery}
     */
    $.fn.dynamicFormCheckbox = function (params) {
        return this.each(function () {
            var el = $(this);
            initializeDynamicForm(el, el,
                function (input, fieldsContainer, options) { // visibilityCallback.
                    if (skipDynamic(input)) {
                        disableForm(fieldsContainer);
                    } else {
                        if (input.prop('checked') === true) {
                            enableForm(fieldsContainer);
                        } else if (input.prop('checked') === false) {
                            disableForm(fieldsContainer);
                        }
                    }
                },
                function (e, input, fieldsContainer, options) { // submitCallback.
                    if (input.prop('checked') === false) {
                        fieldsContainer.remove();
                    }
                },
                params);
        });
    };
}(jQuery)); // dynamicFormRadio + dynamicFormCheckbox

(function ($) {
    /**
     * @param {object} params
     * @returns {jQuery}
     */
    $.fn.autoSearchAjax = function (params) {
        /**
         * @param {jQuery} select
         */
        var initializeAutoSearchAjax = function (select) {
            var options = $.extend(true, {
                cssClass: {
                    field: 'field',
                    open: 'autosearchopen',
                    tags: 'autocompletetags',
                    opentags: 'autocompletetagsopen',
                    tag: 'autosearchtag',
                    search: 'autocompletesearch',
                    entry: 'autosearchentry',
                    search_ctnr: 'autocompletesearch_input_ctnr',
                    searching: 'autosearch_loading',
                    openentry: 'autosearchentryopen',
                    entry_radio: 'radio_ctnr',
                    entry_checkbox: 'checkbox_ctnr'
                },
                data: {
                    endpoint: 'autosearchajax-endpoint',
                    redirectappend: 'autosearch-redirectappend',
                    redirectappendpattern: 'autosearch-redirectappend-pattern',
                    tagsource: 'autosearch-tagsource'
                }
            }, params);

            if (select.data(options.data.endpoint) === undefined) {
                throw new Error('L\'endpoint est nécessaire pour une autocomplétion AJAX');
            }

            var endpoint = select.data(options.data.endpoint);
            var isMultiple = select.attr('multiple') !== undefined;

            var parent = select.closest('.' + options.cssClass.field);

            if (parent.length === 1) {
                select.hide();

                var ctnttags = $('<div class="' + options.cssClass.tags + '"></div>');

                var search = $('<input type="text" value="" autocomplete="nope" placeholder="Rechercher ..." />');

                var ctnt = $('<div class="' + options.cssClass.search + '"></div>');

                var container = $('<div class="' + select.attr('class') + '"></div>');

                ctnt.append(search).append(select).append(container).append(ctnttags);
                search.wrap($('<div class="' + options.cssClass.search_ctnr + '">'));

                parent.append(ctnt);

                select.removeAttr('class');

                var redirectappend = select.data(options.data.redirectappend);
                redirectappend = redirectappend !== undefined && parseInt(redirectappend) === 1;

                var keyUpTimeout = null;
                var isFetching = false;

                /**
                 *
                 * @param {object} data
                 * @returns {jQuery}
                 */
                var appendAjaxResultEntry = function (data) {
                    var span = $('<span>').addClass(options.cssClass.entry);

                    var id = select.attr('name') + '_entry_' + data.value;

                    var input = $('<input>').attr(
                        {'id': id, 'name': select.attr('name') + '_fake', 'value': data.value}
                    );

                    var label = $('<label>').attr('for', id).text(data.text);

                    span.append(input, label);

                    if (isMultiple) { // On affiche un checkbox.
                        span.addClass(options.cssClass.entry_checkbox);
                        input.attr('type', 'checkbox');
                    } else { // On affiche un radio.
                        span.addClass(options.cssClass.entry_radio);
                        input.attr('type', 'radio');
                    }

                    // Tag déjà présent ?

                    var tag = ctnttags.find('[data-' + options.data.tagsource + '="' + data.value + '"]');
                    if (tag.length === 1) {
                        input.prop('checked', true);
                    }

                    container.append(span.addClass(options.cssClass.openentry));

                    return span;
                };

                /**
                 * @param {bool} keepValue
                 */
                var closeResults = function (keepValue) {
                    if (keepValue === undefined || keepValue !== undefined && keepValue === false) {
                        search.val('');
                    }
                    container.removeClass(options.cssClass.open);
                    container.empty();
                };

                /**
                 * @param Event e
                 */
                var eventClick = function (e) {
                    var canClose = true;

                    if (e.target === container.get(0)) {
                        // Conteneur direct.
                        canClose = false;
                    } else {
                        var targetContainer = $(e.target).closest('div');
                        if (targetContainer.length === 1 && targetContainer.get(0) === container.get(0)) {
                            // Enfant du conteneur.
                            canClose = false;
                        }
                    }

                    if (canClose) {
                        closeResults();
                    }
                };

                var searchKeyUp = function () {
                    closeResults(true);

                    if (keyUpTimeout !== null) {
                        clearTimeout(keyUpTimeout);
                        keyUpTimeout = null;
                    }
                    var inputValue = $.trim(search.val()).toLocaleLowerCase();

                    if (inputValue.length === 0) {
                        return;
                    }

                    keyUpTimeout = setTimeout(function () {
                        search.prop('readonly', true).closest('.' + options.cssClass.search_ctnr).addClass(options.cssClass.searching);
                        isFetching = true;

                        container.removeClass(options.cssClass.open);
                        container.empty();

                        $.get(endpoint + '?name=' + encodeURI(inputValue))
                            .done(function (data) {
                                if (data.length > 0) {
                                    $(data).each(function () {
                                        appendAjaxResultEntry(this).find('input').on('change', inputChange);
                                    });

                                    container.addClass(options.cssClass.open);
                                }
                            })
                            .fail(function () {
                                // Au cas ou !
                                alert('Il y a eu une erreur lors de la tentative de récupération des données!');
                            })
                            .always(function () {
                                search.prop('readonly', false).closest('.' + options.cssClass.search_ctnr).removeClass(options.cssClass.searching);
                                isFetching = false;
                            });
                    }, 500)
                };

                var inputChange = function () {
                    if (redirectappend) {
                        if (!isMultiple) {
                            var checkedValue = container.find('input[type="radio"]:checked');
                            if (checkedValue.length === 1) {
                                checkedValue = checkedValue.val();

                                if ($.isNumeric(checkedValue)) {
                                    var url = cleanURL(window.location.href);

                                    // Un pattern de recomposition d'URL est présent en mode append ?
                                    var pattern = select.data(options.data.redirectappendpattern);
                                    if (pattern !== undefined) {
                                        var regex = new RegExp(pattern, 'gi');
                                        var urlPattern = url.split(regex).filter(function (n) {
                                            return $.trim(n) !== '';
                                        });
                                        var groupsCount = (new RegExp(regex.toString() + '|')).exec('').length - 1;
                                        urlPattern = urlPattern.slice(0, groupsCount - 1);
                                        url = urlPattern.join('/');
                                    }

                                    container.closest('form').css({position: 'relative'}).prepend(
                                        $('<div>').css({
                                            'background-color': 'rgba(255, 255, 255, 0.5)',
                                            bottom: 0,
                                            left: 0,
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            'z-index': 100
                                        })
                                    );

                                    window.location.href = cleanURL(url + '/' + checkedValue);
                                }
                            }
                        }
                    } else {
                        ctnttags.removeClass(options.cssClass.opentags);
                        ctnttags.empty();

                        var cur = null;
                        var option = null;

                        container.find('input').each(function () {
                            cur = $(this);
                            if (cur.is(':checked')) {
                                option = select.find('option[value="' + cur.val() + '"]');
                                if (option.length === 0) {
                                    // On l'ajoute dans le select puisque la data qui provient
                                    // du résultat AJAX est plus fraiche que le select.
                                    select.append(
                                        $('<option>').attr('value', cur.val())
                                            .text(cur.next('label').text()).prop('selected', true)
                                    );
                                } else if (option.length === 1) {
                                    option.prop('selected', true);
                                }
                            } else {
                                select.find('option[value="' + cur.val() + '"]').prop('selected', false);
                            }
                        });

                        if (!isMultiple) {
                            closeResults();
                        }

                        select.find('option:selected').each(function () {
                            if ($.trim($(this).val()) === '') {
                                return; // continue.
                            }

                            var tag = $('<span class="' + options.cssClass.tag + '">').text($(this).text())
                                .attr('data-' + options.data.tagsource, $(this).val());

                            ctnttags.append(tag);

                            tag.on('click', function () {
                                select.find('option[value="' + $(this).data(options.data.tagsource) + '"]')
                                    .prop('selected', false);

                                $(this).remove();

                                if (ctnttags.children().length === 0) {
                                    ctnttags.removeClass(options.cssClass.opentags);
                                }
                            });
                        });

                        if (ctnttags.children().length > 0) {
                            ctnttags.addClass(options.cssClass.opentags);
                        }
                    }
                };

                search.on('keyup', searchKeyUp);

                winClick.register(eventClick);

                if (redirectappend) {
                    // Lorsque le champ est utilisé en redirectappend (options.data.redirectappend)
                    // il ne doit pas être envoyé avec le reste des champs du formulaire.
                    select.closest('form').on('submit', function () {
                        container.empty();
                        select.removeAttr('name');
                    });
                }

                inputChange(); // Initialisation!
            }
        };

        return this.each(function () {
            initializeAutoSearchAjax($(this));
        });
    };
}(jQuery)); // autoSearchAjax

(function ($) {
    $.fn.deepSelector = function (params) {
        var initializeDeepSelector = function (select) {
            var options = $.extend(true, {
                cssClass: {
                    field: 'field',
                    container: 'deepselector-container',
                    active: 'deepselector-active',
                    subcontainer: 'deepselector-subcontainer',
                    clone: 'deepselector-clone'
                },
                data: {
                    id: 'deepselector-id',
                    label: 'deepselector-label'
                }
            }, params);

            var container = $('<div>').addClass(options.cssClass.container);

            var clone = $('<input type="text" name="'
                + select.attr('name') + '" class="' + options.cssClass.clone + '" value="" />');

            if (select.attr('required') === 'required') {
                clone.attr('required', 'required');
                select.removeAttr('required');
            }

            select.removeAttr('name').after(container);

            var selectedVal = $.trim(select.val());

            /**
             * @returns {object}
             */
            var getSelectCollection = function () {
                var collection = {};
                select.find('>option').each(function () {
                    var $o = $(this);
                    var id = $o.data(options.data.id);
                    var label = $o.data(options.data.label);
                    if (id !== undefined && label !== undefined) {
                        var parentkey = 'parentkey_' + id;
                        if (collection[parentkey] === undefined) {
                            collection[parentkey] = {id: id, label: label, children: []};
                        }
                        collection[parentkey].children.push({
                            id: $(this).val(),
                            label: $.trim($(this).text()),
                            selected: selectedVal === $.trim($(this).val())
                        });
                    }
                });
                return collection;
            };

            /**
             * @param {jQuery} entry
             * @returns {jQuery}
             */
            var createEntry = function (entry) {
                var div = null;

                if (entry.children.length > 0) {
                    var isCurrent = false;
                    var input = $('<input>').attr({type: 'radio'});
                    var inputLabel = $('<label>').text(entry.label);
                    var select = $('<select>');

                    /**
                     * @param {jQuery} select
                     * @param {string} value
                     * @param {string} label
                     */
                    var appendOption = function (select, value, label, selected) {
                        var option = $('<option>').attr('value', value).html(label);
                        select.append(option);

                        if (selected === true) {
                            option.prop('selected', true);
                        }
                    };

                    appendOption(select, '', 'Choisissez une option...', false);

                    $(entry.children).each(function () {
                        appendOption(select, this.id, this.label, this.selected);
                        if (!isCurrent && this.selected) {
                            isCurrent = true;
                        }
                    });

                    div = $('<div>').addClass(options.cssClass.subcontainer);

                    if (isCurrent) {
                        input.prop('checked', true);
                        clone.val(select.val());
                        div.addClass(options.cssClass.active);
                    } else {
                        select.attr('disabled', 'disabled');
                    }

                    div.append(inputLabel.prepend(input), select);

                    select.on('change', function () {
                        clone.val($(this).val());
                    });
                }

                return div;
            };

            var collection = getSelectCollection();
            for (var i in collection) {
                container.append(createEntry(collection[i]));
            }

            var disableAll = function () {
                container.find('.' + options.cssClass.active).removeClass(options.cssClass.active);
                container.find('select').attr('disabled', 'disabled').each(function () {
                    $(this).find('option:selected').prop('selected', false);
                    $(this).trigger('change');
                });
                container.find('input[type="radio"]').prop('checked', false);
                clone.val('');
            };

            /**
             * @param {jQuery} radio
             */
            var enableRadio = function (radio) {
                radio.prop('checked', true);
                radio.closest('.' + options.cssClass.subcontainer)
                    .addClass(options.cssClass.active)
                    .find('select').removeAttr('disabled');
            };

            container.find('input[type="radio"]').change(function () {
                disableAll();
                enableRadio($(this));
            });

            container.append(clone);
        };

        return this.each(function () {
            initializeDeepSelector($(this));
        });
    }
}(jQuery)); // deepSelector

(function ($) {
    /**
     * @returns {jQuery}
     */
    $.fn.skipFormValidation = function () {
        $(this).on('click', function (e) {
            $(this).closest('form').find('[required="required"]')
                .removeAttr('required')
                .attr('data-skiprequired', 1);
        });
        return this;
    };
}(jQuery)); // skipFormValidation

$(document.body).ready(function () {
    $('form [data-form-skip-validation="1"]').skipFormValidation();

    $('form .multifield').multipleField();

    // Plugins testés pour du support en multipleField:
    // - - - - - - - - - - - - - - - - - - - - - - - - - - -

    $('form').constraintValidation();
    $('form').disabledSelect();
    $('form').disabledCheckbox();
    $('form').chooseDefault();

    // Plugins qui n'ont pas encore été testé pour du
    // support en multipleField:
    // - - - - - - - - - - - - - - - - - - - - - - - - - - -

    $('form .autosearch.autosearchajax').autoSearchAjax();
    $('form select.deepselector').deepSelector();

    globalListener.getInstance().add(function (key, form) {
        if (key === 'initform_after') {
            form.disabledSelect();
            form.disabledCheckbox();
            form.chooseDefault();
        }
    });

    // Applications dynamiques.
    // - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // Ces deux instanciations doivent impérativement être réalisées en dernier
    // pour laisser le temps aux autres plugins de s'initialiser correctement.

    $('form .dynamic.dynamic-checkbox').dynamicFormCheckbox();
    $('form .dynamic.dynamic-radio').dynamicFormRadio();
});
