import {globalListener} from "./script";

(function ($) {
    $.fn.extend ({
        clickoutside: function (handler) {
            var $$ = this;
            var inner = 0;
            $$.mouseenter (function () {inner = 1;}).mouseleave (function () {inner = 0;});
            $('body').bind ('click', function (event) {if (inner < 1) handler (event, $$);});
            return this;
        } // clickoutside ();
    });
})(jQuery);


var customselect = function () {
	$(".tab_menu_select," +
		".data_grid select:not(.deepselector):not([multiple]):not(.has_customselect):not(.no_custom)," +
		".form select:not([data-autosearchajax-endpoint]):not(.deepselector):not([multiple]):not(.has_customselect):not(.no_custom)," +
		".preferences select:not(.has_customselect):not(.no_custom)").each(function(){
		$(this).addClass("has_customselect");
		$(this).wrap("<span class='customselect-container' />")
		$(this).after("<span class='customselect'><span class='customselect-inner'/></span>");
		$(this).next().find('.customselect-inner').html($(this).find("option[value='"+$(this).val()+"']").text());

		//console.log('customselectinit!');

	}).on('change',function(e){
		e.preventDefault();
		$(this).next().find('.customselect-inner').html($(this).find("option[value='"+$(this).val()+"']").text());
	});

}; // customselect ();

var navInit = function(){
	$(".nav_toggler").on('click',function(e){
		$('body').removeClass('action_open');
		$('body').toggleClass('nav_open');
	});
	$(".action_toggler").on('click',function(e){
		$('body').removeClass('nav_open');
		$('body').toggleClass('action_open');
	});


	$("#nav li").on('click',function(e){
		currentState = $(this).hasClass('open');
		$("#nav li").removeClass('open');
		if (currentState) {
			$(this).removeClass('open');
		}
		else{
			$(this).addClass('open');
		}
	});
	$("#nav li.active").each(function(){
		$(this).addClass('open');
	});
	$("#nav a").on('click',function(e){
		e.stopPropagation();
	});
	$("#nav li").on('mouseenter',function(e){
		$(this).addClass('hovered');
	});
	$("#nav li").on('mouseleave',function(e){
		$(this).removeClass('hovered');
	});

}

var tabMenuInit = function () {
	$(".tab_menu").each(function(){

		var currentTabMenu = $(this);

		var	$ctnr = $(this).closest('.tab_menu_ctnr');
		var $selectmenu = $('<select class="tab_menu_select">')
		$(this).find('li').each(function(index, el) {
			var $opt = $('<option />');
			$opt.attr('value',$(this).find('a').attr('href')).text($(this).text());
			if ($(this).hasClass('active')) {
				$opt.attr('selected','selected');
			}
			$selectmenu.append($opt);
		});
		$selectmenu.on('change',function(e){
			e.preventDefault();
			$('.tab_menu [href="'+$(this).val()+'"]').trigger('click');
		});
		$ctnr.append($selectmenu);

		var getCurrentTabIndex = function () {
            return currentTabMenu.find('>li').index(currentTabMenu.find('>li.active'));
		};

		var switchBtnStates = function () {
            var index = getCurrentTabIndex();
            if (index > -1) {
                $('[data-showonly-tabstep]').hide();
                $('[data-hideonly-tabstep]').show();
                $('[data-showonly-tabstep="' + index + '"]').show();
                $('[data-hideonly-tabstep="' + index + '"]').hide();
            }
		};

		$('a[data-previous-tabstep="on"]').on('click', function () {
			var index = getCurrentTabIndex();
            if (index > 0) { // exclu: -1 et 0
                $(currentTabMenu.find('>li').get(index - 1)).find('>a').trigger('click');
            }
		});

        $('[data-savenext="on"]').on('click', function () {
            var index = getCurrentTabIndex();
            if (index > -1) {
                sessionStorage.setItem("tab_state",$(currentTabMenu.find('>li').get(index + 1)).find('>a').attr('href'));
            }
        });




		$(this).find('a').on('click', function(e) {
			e.preventDefault();
			var href=$(this).attr('href');
			if (href.charAt(0) === '#') {
				$(this).closest('.tab_menu').find('li').removeClass('active');
				$(this).closest('li').addClass('active');
				$('.tabs .tab').removeClass('open');
				$($(this).attr('href')).addClass('open');
			}
			else{
				location.href = href;
			}

            switchBtnStates();
		});

	    if ($('.tabs').length) {
			globalListener.getInstance().add(function (key, form) {
			    if (key === 'on_constraint_submit') {
	            	$(".tab_menu_ctnr").removeClass('error');
	            	$(".tab_menu li").removeClass('error');
	            	$('.tabs .tab').each(function(index, el) {
	            		if($(this).find('.field-error').length){
	            			$('.tab_menu li a[href="#'+$(this).attr('id')+'"]').closest('li').addClass('error');
	            			$(".tab_menu_ctnr").addClass('error');
	            		}
	            	});
		    	}
			});


			if (sessionStorage !== null) {
				$('.tab_state_saver').on('click', function(event) {
					sessionStorage.setItem("tab_state",$('.tab_menu li.active a').attr('href'));
				});
				if (sessionStorage.getItem('tab_state') !== null) {
					$('.tab_menu li a[href="'+sessionStorage.getItem('tab_state')+'"]').trigger('click');
				}
				sessionStorage.removeItem("tab_state");
			}

            switchBtnStates();
		}
	});


}; // customselect ();


var prepareDataDelay = function () {
    var applyDelayToHide = function (elem) {
        setTimeout(function () {
            elem.remove();
        }, parseInt(elem.data('hide-delay')));
    };
    $('[data-hide-delay]').each(function () {
        applyDelayToHide($(this));
    });
};

var prepareDisabledConsultation = function () {
    var hasJob = $('#appbundle_consultation_person_form_has_job');
    var mainForm = hasJob.closest('form');
    if (hasJob.length === 1 && mainForm.hasClass('forceadh')) {
        var pmExistsYes = $('#appbundle_consultation_person_form_person_corporation_exists_0');
        var pmExistsNo = $('#appbundle_consultation_person_form_person_corporation_exists_1');

        hasJob.attr('disabled', 'disabled');
        pmExistsYes.attr('disabled', 'disabled');
        pmExistsNo.attr('disabled', 'disabled');

        hasJob.closest('.field').addClass('field-disabled');
        pmExistsNo.closest('.field').addClass('field-disabled');

        mainForm.on('submit', function () {
            hasJob.removeAttr('disabled');
            pmExistsYes.removeAttr('disabled');
            pmExistsNo.removeAttr('disabled');
        });
    }
};


$(document.body).ready(function () {
    // document ready pour l'intégration.

    navInit();
    tabMenuInit();
    customselect();

    $(".multi_action li").on('click',function(e){
    	var opened = $(this).closest('.multi_action').hasClass('open')
    	$(".multi_action").removeClass('open');
    	if (!opened) $(this).closest('.multi_action').addClass('open');
	}).clickoutside(function(){
		$(".multi_action").removeClass('open');
	});

	$(".multi_action .btn").on('click',function(e){
		e.stopPropagation();
	});

	$('.multi_action').each(function(index, el) {
		if ($(this).find('.btn').length <=1 ) {
			$(this).removeClass('multi_action').addClass('actions');
		}
	});

	$('.multifield-add').addClass('btn black secondary');

	$('.modal_opener').on('click',function(e){
		e.preventDefault();
		$.magnificPopup.open ({
            items: {
                src: $(this).attr('href'),
                type: 'inline',
                modal: true
            }
        });
	});
	$('.modal_closer').on('click',function(e){
		e.preventDefault();
		$.magnificPopup.close ();
	});


	$('.plan_chooser_ctnr').each(function(index, el) {
		var $ctnr = $(this);
		var $select = $ctnr.find('select');
		var $list = $('<ul/>').addClass('plan_chooser');

		$select.find('option').each(function(index, el) {
			var $opt = $(this);
			var value = $opt.attr('value');
			if (value !== "") {

				var $li = $('<li />').attr('data-value',value);
				
				$li.append($('<span />').text($opt.text()));

				if ($opt.is(':selected')) {
					$li.addClass('active');
				}
				$list.append($li);
			}
		});

		$ctnr.append($list);

		$list.find('li').on('click' ,function(e) {
			e.preventDefault();
			$list.find('li').removeClass('active');
			$(this).addClass('active');

			$select.val($(this).attr('data-value')).trigger('change');
		});

	});


    globalListener.getInstance().add(function (key, form) {
        if (key === 'initform_after') {
            if (form.find('select').length > 0) {
                customselect();
            }
        }
    });

    prepareDataDelay();
    prepareDisabledConsultation();

});
